import { Fragment } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import { androidId, useHomeNews, onlineId } from "../data";
import GameList from "../GameList";
import GameNews from "../GameNews";
import Banner from "../Banner";
import Title from "../Title";
import { AppContext } from "../../App";
function Home() {
  const navigate = useNavigate();
  const news = useHomeNews(4);
  const [, , , setCategory] = useOutletContext<AppContext>();
  return (
    <Fragment>
      <Banner></Banner>
      <GameList
        title="Our best Online games"
        type={onlineId}
        onClickTitle={() => {
          setCategory("All");
          navigate("/online");
        }}
      ></GameList>
      {/* <GameList
        title="Our best Android games"
        type={androidId}
        onClickTitle={() => {
          setCategory("All");
          navigate("/android");
        }}
      ></GameList> */}

      <Title
        title="Game news"
        onClick={() => navigate("/news")}
        right="All News"
      ></Title>
      <div
        style={{
          marginTop: "10px",
        }}
      >
        {news.map((item, index) => (
          <GameNews data={item} key={index}></GameNews>
        ))}
      </div>
    </Fragment>
  );
}

export default Home;
