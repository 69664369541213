import React from "react";
import { Image } from "antd-mobile";
import styles from "./index.module.css";
import { useNavigate, useOutletContext } from "react-router-dom";
import { GameType, onlineId } from "../data";
import { AppContext } from "../../App";
import { Fragment } from "react";

export interface GameProps {
  /**
   * 游戏id，递增唯一
   */
  id: number;
  /**
   * 缩略图链接
   */
  thumbnail: string;
  /**
   * 游戏名称
   */
  title: string;
  /**
   * 游戏链接
   */
  url: string;
  /**
   * 游戏类型，12: Online Games；13:Android Games
   */
  platform: GameType;
}

function GameCard(props: { data: GameProps, index: number}) {
  const navigate = useNavigate();
  const [, setShowLogin] = useOutletContext<AppContext>();
  const showCategory = props.index === 1;
  return (
    <div style={{marginBottom: "5px"}}>
      <div className={styles.root}>
        <img
          className={styles.img}
          // src={`${process.env.REACT_APP_ADMIN_URL}/${props.data.thumbnail}`}
          src={`${props.data.thumbnail}`}
          onClick={() => navigate(`/detail/${props.data.id}`)}
        ></img>
        <div className={styles.action}>
          <div>{props.data.title}</div>
          <button>Action</button>
        </div>
        <button
          className={styles.play}
          onClick={() => {
            if (localStorage.getItem("token")) {
              // window.location.href = props.data.url;
              navigate(`/detail/${props.data.id}`)
            } else {
              setShowLogin(false);
              // window.location.href = props.data.url;
              navigate(`/detail/${props.data.id}`)
              // setShowLogin(true);
            }
          }}
        >
          {props.data.platform === onlineId ? "Play Now" : "Download Now"}
        </button>
      </div>
      <Fragment>
        { showCategory && (
          <div style={{ margin: "5px 0px"}}>
            <ins className="adsbygoogle"
            style={{display:"block"}}
            data-ad-client="ca-pub-"
            data-ad-slot=""
            data-ad-format="auto"
            data-full-width-responsive="true"></ins>
            <script>
                (adsbygoogle = window.adsbygoogle || []).push({});
            </script>
          </div>
        )}
      </Fragment>
    </div>

  );
}

export default GameCard;
