import styles from "./index.module.css";
export interface MenuProps {
  icon?: string;
  title: string;
  link: string;
}

function Menu(props: {
  menus: Array<MenuProps>;
  onSelect: (item: MenuProps) => void;
}) {
  return (
    <div className={styles.menus}>
      {props.menus.map((item, index) => (
        <div key={index} onClick={() => props.onSelect(item)}>
          {item.title}
        </div>
      ))}
    </div>
  );
}

export default Menu;
