import { Image } from "antd-mobile";
import { useNavigate } from "react-router-dom";
import styles from "./index.module.css";
export interface GameNewsProps {
  /**
   * news id，新闻 id
   */
  id: number;
  /**
   * Abstract，新闻摘要
   */
  abstract: string;
  /**
   * pic_url，新闻图片链接
   */
  pic_url: string;
  /**
   * Title，新闻标题
   */
  title: string;
}

function GameNews(props: { data: GameNewsProps }) {
  const navigate = useNavigate();
  return (
    <div
      className={styles["news-root"]}
      onClick={() => navigate(`/news/${props.data.id}`)}
    >
      <Image
        className="img"
        // src={`${process.env.REACT_APP_ADMIN_URL}/${props.data.pic_url}`}
        src={`${props.data.pic_url}`}
        width={64}
        height={64}
        fit="cover"
      ></Image>
      <div className={styles["new-content"]}>
        <div>{props.data.title}</div>
        <p>{props.data.abstract}</p>
      </div>
    </div>
  );
}

export default GameNews;
