import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

import { BrowserRouter, Route, Routes, useHref } from "react-router-dom";
import Home from "./components/Body/Home";
import Detail from "./components/Body/Detail";
import News from "./components/GameNews/News";
import NewsDetail from "./components/GameNews/NewsDetail";
import { androidId, onlineId } from "./components/data";
import GameList from "./components/GameList";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route element={<App />}>
          <Route path="/" element={<Home />}></Route>
          <Route path="/detail/:gameId" element={<Detail />}></Route>
          <Route
            path="/online"
            element={
              <GameList
                type={onlineId}
                onClickTitle={() => useHref("/online")}
              ></GameList>
            }
          ></Route>
          {/* <Route
            path="/android"
            element={
              <GameList
                type={androidId}
                onClickTitle={() => useHref("/android")}
              ></GameList>
            }
          ></Route> */}
          <Route path="/news" element={<News />}></Route>
          <Route path="/news/:newsId" element={<NewsDetail />}></Route>
        </Route>
      </Routes>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
