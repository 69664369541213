import { Fragment } from "react";
import { useLocation, useOutletContext } from "react-router-dom";
import { AppContext } from "../../App";
import { GameType, useCategory, useGames } from "../data";
import GameCard from "../GameCard";
import Title from "../Title";

function GameList(props: {
  title?: string;
  type: GameType;
  onClickTitle: () => void;
}) {
  const games = useGames(props.type);
  const location = useLocation();

  const [, , strategy] = useOutletContext<AppContext>();

  const showCategory = ["/online", "/android"].includes(location.pathname);
  const categoryGames = useCategory(
    props.type,
    strategy === undefined ? "All" : strategy,
    showCategory
  );

  // 在这里处理
  for (var i=0; i<games.length; i++){
    games[i].url = `https://game4fun.co/playgames.html?gameId=${games[i].id}`
  }
  return (
    <Fragment>
      {props.title && (
        <Title
          title={props.title}
          onClick={props.onClickTitle}
          right="All Games"
        ></Title>
      )}
      <div
        style={{
          marginTop: "10px",
        }}
      >
        {(showCategory ? categoryGames : games).map((item, index) => (
          <GameCard data={item} key={index} index={index}></GameCard>
        ))}
      </div>
    </Fragment>
  );
}

export default GameList;
