let baseURL=process.env.REACT_APP_SERVER_URL
function Footer() {
  return (
    <>
      <p style={{marginBottom: "5px"}}><a href={`${baseURL}/privacy.html`}>privacy</a> | <a href={`${baseURL}/about_us.html`}>about us</a></p>
      <p style={{marginTop: "10px"}}>Copyright © 2022 Game. All rights Reserved.</p>
    </>
  );
}

export default Footer;
