import { useParams } from "react-router-dom";
import { useNewsDetail } from "../data";
import styles from "./detail.module.css";

function NewsDetail() {
  let { newsId } = useParams();
  const newsDetail = useNewsDetail(Number(newsId));

  return (
    <div
      className={styles.content}
      dangerouslySetInnerHTML={{ __html: newsDetail?.content || "" }}
    ></div>
  );
}

export default NewsDetail;
