import { Toast } from "antd-mobile";
import { Fragment, useState } from "react";
import { login, register } from "../data";
import styles from "./index.module.css";

export interface UserToken {
  /**
   * 登录账号
   */
  mobile_no: string;
  /**
   * 令牌
   */
  token: string;
}

function Login(props: {
  onClose: () => void;
  setToken: React.Dispatch<React.SetStateAction<UserToken | undefined>>;
}) {
  const [form, setForm] = useState({
    mobile_number: "",
    password: "",
    confirm_password: "",
  });
  const [formType, setFormType] = useState<"Login" | "Signup">("Login");
  const [error, setError] = useState(false);

  return (
    <div className={styles["login-content"]}>
      <div className={styles["login-content-close"]} onClick={props.onClose}>
        <img src="/assets/close.svg" alt=""></img>
      </div>
      <div className={styles["login-content-content"]}>
        <p>{formType}</p>
        <div className={styles.label}>Mobile number</div>
        <input
          className={styles["login-content-input"]}
          value={form.mobile_number}
          placeholder="Your mobile number"
          onInput={(ele) =>
            setForm({
              ...form,
              ...{ mobile_number: (ele.target as HTMLInputElement)?.value },
            })
          }
        ></input>
        {form.mobile_number === "" && error && (
          <div className={styles.error}>Mobile Number can not be Empty</div>
        )}
        <div className={styles.label}>Password</div>
        <input
          className={styles["login-content-input"]}
          type="password"
          value={form.password}
          placeholder="Password"
          onInput={(ele) =>
            setForm({
              ...form,
              ...{ password: (ele.target as HTMLInputElement)?.value },
            })
          }
        ></input>
        {form.password.length < 6 && error && (
          <div className={styles.error}>
            At least 6 characters
          </div>
        )}
        {formType === "Signup" && (
          <Fragment>
            <div className={styles.label}>Confirm Password</div>
            <input
              className={styles["login-content-input"]}
              type="password"
              value={form.confirm_password}
              placeholder="Confirm Password"
              onInput={(ele) =>
                setForm({
                  ...form,
                  ...{
                    confirm_password: (ele.target as HTMLInputElement)?.value,
                  },
                })
              }
            ></input>
            {form.confirm_password.length < 6 && error && (
              <div className={styles.error}>
                At least 6 characters
              </div>
            )}
            {form.password !== "" &&
              form.confirm_password !== "" &&
              form.password !== form.confirm_password && (
                <div className={styles.error}>Unmatched password</div>
              )}
          </Fragment>
        )}
        <div
          className={styles["login-content-confirm"]}
          onClick={() => {
            setError(false);
            if (formType === "Login") {
              if (form.mobile_number === "" || form.password.length < 6) {
                setError(true);
                return;
              }

              login(form.mobile_number, form.password, (msg, token) => {
                if (token) {
                  Toast.show({
                    icon: "success",
                    content: "Login Success",
                  });
                  props.setToken(token);
                  window.localStorage.setItem("token", JSON.stringify(token));
                  props.onClose();
                } else {
                  Toast.show({
                    icon: "fail",
                    content: msg,
                  });
                }
              });
            } else {
              if (
                form.mobile_number === "" ||
                form.password.length < 6 ||
                (form.confirm_password.length < 6 &&
                  form.password !== form.confirm_password)
              ) {
                setError(true);
                return;
              }
              register(form.mobile_number, form.password, (code, msg) => {
                Toast.show({
                  icon: code === 200 ? "success" : "fail",
                  content: msg,
                });
                if (code === 200) {
                  props.onClose();
                }
              });
            }
            setForm({ mobile_number: "", password: "", confirm_password: "" });
          }}
        >
          {formType === "Login" ? "Login" : "Sign up"}
        </div>
        <div className={styles.gameplus}>
          {formType === "Login"
            ? "New to GamePlus?"
            : "Already have an account"}
        </div>
        <div
          className={styles.signup}
          onClick={() => {
            setFormType(formType === "Login" ? "Signup" : "Login");
            setForm({ mobile_number: "", password: "", confirm_password: "" });
            setError(false);
          }}
        >
          {formType === "Login" ? "Sign Up for FREE" : "LOGIN"}
        </div>
      </div>
    </div>
  );
}

export default Login;
