import { useEffect, useState } from "react"
import { BannerProps } from "./Banner"
import { GameProps } from "./GameCard"
import { GameCategoryProps, GameStrategy } from "./GameCategory"
import { GameDetailProps, GameScreenshot } from "./GameDetail"
import { GameNewsProps } from "./GameNews"
import { GameNewsDetailProps } from "./GameNews/News"
import { UserToken } from "./Login"
import { MenuProps } from "./Menu"

let baseURL=process.env.REACT_APP_SERVER_URL

// 
export const onlineId = 0
export const androidId = 1

export type GameType=0|1

export const useGames= (id:number) => {
  const [data, setData] = useState<Array<GameProps>>()
  useEffect(() => {
    fetch(`${baseURL}/api/groups/games?groupId=${id}`).then(res => res.json()).then(res => {
      setData(res.data.length>0?res.data[0].game_list:[])
    })
  },[id])  
  return data||[]
}

  
export const useHomeNews= (count: number)=>{
  const [data, setData] = useState<Array<GameNewsProps>>()
  useEffect(() => {
    fetch(`${baseURL}/api/news?count=${count}`).then(res => res.json()).then(res => {
      setData(res.data)
    })
  },[count])  
  return data||[]
}

export const useAllNews =  ()=>{
  const [data, setData] = useState<Array<GameNewsProps>>()
 
  useEffect(() => {
    fetch(`${baseURL}/api/news`).then(res => res.json()).then(res => {
      setData(res.data)
    })
  },[])  
  return data||[]
}

export const useNewsDetail = (news_id: number | undefined) => {
  

  const [data, setData] = useState<GameNewsDetailProps>()
      useEffect(() => {
        if (news_id) {
          fetch(`${baseURL}/api/news/${news_id}`).then(res => res.json()).then(res => {
            setData(res.data)
          })
        }
      }, [news_id]) 
  return data
}

export const menus: Array<MenuProps> = [
  {
    title: "Online Games",
    link:"/online"
  },
  // {
  //   title: "Android Games",
  //   link:"/android"
  // },
  {
    title: "Game News",
    link:"/news"
  },
]



export const useGameDetail = (gameId: number) => {
  const [data, setData] = useState<GameDetailProps>()
  useEffect(() => {
    fetch(`${baseURL}/api/games/${gameId}`).then(res => res.json()).then(res => {
      for (var i=0; i<res.data.length; i++){
        res.data[i].url = `https://game4fun.co/playgames.html?gameId=${res.data[i].id}`
      }
      setData(res.data)
    })
  },[gameId])
  return data
}

export const useGameScreenshot = (gameId: number) => {
  const [data, setData] = useState<Array<GameScreenshot>>()
  useEffect(() => {
    fetch(`${baseURL}/api/screenshot/${gameId}`).then(res => res.json()).then(res => {
      setData(res.data)
    })
  },[gameId])
  return data||[]
}


export const all_category: Array<GameCategoryProps> = [
  {
    icon: "/assets/all.svg",
    strategy:"All"
  },
  {
    icon: "/assets/Action.png",
    strategy:"Action"
  },
  {
    icon: "/assets/Adventure.png",
    strategy:"Adventure"
  },
  {
    icon: "/assets/Casual.png",
    strategy:"Casual"
  },
  {
    icon: "/assets/Puzzle.png",
    strategy:"Puzzle"
  },
  {
    icon: "/assets/Racing.png",
    strategy:"Racing"
  },
  {
    icon: "/assets/Shooter.png",
    strategy:"Shooter"
  },
  {
    icon: "/assets/Sports.png",
    strategy:"Sports"
  },
  {
    icon: "/assets/Strategy.png",
    strategy:"Strategy"
  }
]

export const useBanners = () => {
  const [data, setData] = useState<Array<BannerProps>>()
  useEffect(() => {
    fetch(`${baseURL}/api/banners`).then(res => res.json()).then(res => {
      setData(res.data)
    })
  },[])
  return data||[]
}

export const login = (mobile_no: string, password: string, callback: (msg: string, token:UserToken)=>void) => {
    let body = new FormData()
    body.set("mobile_no", mobile_no)
    body.set("password", password)
    body.set("area_no","86")
    fetch(`${baseURL}/api/login`, {
      body,
      method:"POST"
    }).then(res => res.json()).then(res => {
      callback(res.msg,res.data)
    })
}

export const register = (mobile_no: string, password: string, callback: (code:number,msg: string) => void) => {
    let body = new FormData()
    body.set("mobile_no", mobile_no)
    body.set("password", password)
    body.set("area_no","86")
    fetch(`${baseURL}/api/register`, {
      body,
      method:"POST"
    }).then(res => res.json()).then(res => {
      callback(res.code,res.msg)
    })
}

export const useCategory = (game_type: GameType, game_strategy: GameStrategy,showCategory:boolean) => {
  
  const [data, setData] = useState<Array<GameProps>>([])
  useEffect(() => {
    if (showCategory) {
      fetch(`${baseURL}/api/strategy_games?game_type=${game_type}&game_strategy=${game_strategy}`).then(res => res.json()).then(res => {
        for (var i=0; i<res.data.length; i++){
          res.data[i].url = `https://game4fun.co/playgames.html?gameId=${res.data[i].id}`
        }
        setData(res.data)
      })
    }
    
  },[game_strategy,game_type,showCategory])
  return data
}