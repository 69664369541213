import { Fragment } from "react";
import { useNavigate } from "react-router-dom";
import { GameNewsProps } from ".";
import { useAllNews } from "../data";
import styles from "./news.module.css";

export interface GameNewsDetailProps extends GameNewsProps {
  /**
   * Content，新闻内容HTML
   */
  content: string;
}

function News() {
  const more_news = useAllNews();
  const navigate = useNavigate();
  return (
    <div className={styles["more-news-root"]}>
      {more_news.map((item, index) => (
        <Fragment key={index}>
          <div onClick={() => navigate(`/news/${item.id}`)}>
            {/* <img src={`${process.env.REACT_APP_ADMIN_URL}/${item.pic_url}`} alt=""></img> */}
            <img src={`${item.pic_url}`} alt=""></img>
            <p className={styles["news-title"]}>{item.title}</p>
            <p className={styles["news-content"]}>{item.abstract}</p>
          </div>
          {index % 2 === 0 && <div />}
        </Fragment>
      ))}
    </div>
  );
}

export default News;
