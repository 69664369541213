import styles from "./App.module.css";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Menu from "./components/Menu";
import { useState } from "react";
import { menus } from "./components/data";
import Login, { UserToken } from "./components/Login";

import { Outlet, useLocation, useNavigate } from "react-router-dom";

import GameCategory, { GameStrategy } from "./components/GameCategory";

export type AppContext = [
  boolean,
  React.Dispatch<React.SetStateAction<boolean>>,
  GameStrategy,
  React.Dispatch<React.SetStateAction<GameStrategy>>
];

function App() {
  const [active, setActive] = useState(false);

  const [showLogin, setShowLogin] = useState(false);
  const [strategy, setStrategy] = useState<GameStrategy>("All");

  const navigate = useNavigate();
  const location = useLocation();

  const showCategory = ["/online", "/android"].includes(location.pathname);

  const _token = window.localStorage.getItem("token");

  const [token, setToken] = useState<UserToken | undefined>(
    _token ? JSON.parse(_token) : undefined
  );

  return (
    <div className={styles.App}>
      <div className={styles.navbar}>
        <Header
          active={active}
          onClick={() => setActive(!active)}
          onLogin={() => setShowLogin(true)}
          mobile_no={token?.mobile_no}
        ></Header>
      </div>
      {showCategory && (
        <div className={styles["category-root"]}>
          <GameCategory
            strategy={strategy}
            setStrategy={setStrategy}
          ></GameCategory>
        </div>
      )}
      {active && (
        <Menu
          onSelect={(item) => {
            setActive(false);
            navigate(item.link);
          }}
          menus={menus}
        ></Menu>
      )}

      <div
        className={styles.body}
        onClick={() => setActive(false)}
        style={{
          minHeight: showCategory ? "76vh" : "81vh",
        }}
      >
        <Outlet context={[showLogin, setShowLogin, strategy, setStrategy]} />
      </div>
      {showLogin && (
        <Login onClose={() => setShowLogin(false)} setToken={setToken}></Login>
      )}
      <div className={styles.footer}>
        <Footer></Footer>
      </div>
    </div>
  );
}

export default App;
