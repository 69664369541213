import React from "react";
import { Space, Image, Button } from "antd-mobile";
import styles from "./index.module.css";
function Title(props: { title: string; onClick?: () => void; right: string }) {
  return (
    <div className={styles.title}>
      <span>{props.title}</span>
      <Button size={"small"} onClick={props.onClick}>
        <Space align="center">
          <span>{props.right}</span>
          <Image src="/assets/right.svg"></Image>
        </Space>
      </Button>
    </div>
  );
}

export default Title;
