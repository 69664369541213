import { Fragment } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { onlineId, useGameDetail, useGameScreenshot } from "../data";
import GameDetail from "../GameDetail";
import GameList from "../GameList";
function Detail() {
  let { gameId } = useParams();
  const navigate = useNavigate();

  const gameDetail = useGameDetail(Number(gameId));

  const screenshot = useGameScreenshot(Number(gameId));

  if (gameDetail !== undefined){
    gameDetail.url = `https://game4fun.co/playgames.html?gameId=${gameDetail.id}`
  }
  return (
    <Fragment>
      {gameDetail && (
        <GameDetail game={gameDetail} screenshot={screenshot}></GameDetail>
      )}
      {gameDetail && (
        <GameList
          title="Our recomended games"
          type={gameDetail.platform}
          onClickTitle={() =>
            navigate(gameDetail.platform === onlineId ? "/online" : "/android")
          }
        ></GameList>
      )}
    </Fragment>
  );
}

export default Detail;
