import { Image } from "antd-mobile";
import styles from "./index.module.css";
import { all_category } from "../data";

export type GameStrategy =
  | "All"
  | "Action"
  | "Adventure"
  | "Casual"
  | "Puzzle"
  | "Racing"
  | "Shooter"
  | "Sports"
  | "Strategy";
export interface GameCategoryProps {
  icon: string;
  strategy: GameStrategy;
}

function GameCategory(props: {
  strategy: GameStrategy;
  setStrategy: React.Dispatch<React.SetStateAction<GameStrategy>>;
}) {
  if (props.strategy === undefined) {
    props.setStrategy("All");
  }

  return (
    <>
      {all_category.map((item, index) => (
        <div
          className={`${styles["category-item"]} ${
            props.strategy === item.strategy ? styles.active : ""
          }`}
          key={index}
          onClick={() => props.setStrategy(item.strategy)}
        >
          <Image width={20} height={20} src={item.icon}></Image>
          <span>{item.strategy}</span>
        </div>
      ))}
    </>
  );
}

export default GameCategory;
